import React, { useEffect, useState } from 'react';
import { scenes } from '../../services/scenes';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useModal } from '../../hooks/modal';
import { session } from '../../services/session';
import Modal from '../Modal';
import XMLParser from 'react-xml-parser';
import Loader from '../Loader';
import addSVG from '../../images/add.svg';
import questionSVG from '../../images/icon-question.svg';
import searchIcon from '../../images/search-icon.png';
import { isLatestApp } from '../../utitls/AppVersion';
import ErrorModal from '../ErrorModal';
import noImageIcon512 from '../../images/no-image-3dframe.jpg';
import CryptoJS from 'crypto-js';

const app = new window.Webex.Application();

const _getFormatedName = sceneName => sceneName.toLowerCase().replace(/ /g, '_');

export default function DashboardPage() {
  let navigate = useNavigate();
  let auth = useAuth();
  let modal = useModal();
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [isSceneListLoaded, setIsSceneListLoaded] = useState(false);
  const [sessionStartError, setSessionStartError] = useState(false);
  const [error, setError] = useState(null);
  const [appReadyError, setAppReadyError] = useState(false);
  const [appReadyErrorMsg, setAppReadyErrorMsg] = useState(null);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [mode, setMode] = useState('viewer');
  const [active, setActive] = useState(false);
  const [activeSceneIndex, setActiveSceneIndex] = useState(0);
  const [sceneKey, setSceneKey] = useState(false);
  const [userName, setUserName] = useState(localStorage.getItem('meeting_username') ? localStorage.getItem('meeting_username') : '');
  const [userEmail, setUserEmail] = useState(null);
  const [hasUserDetials, setHasUserDetials] = useState(false);
  const [stripePlanError, setStripePlanError] = useState(false);

  useEffect(() => {
    console.log("app:",app);
    auth.isLoggedIn(() => { });
    // if (app.deviceType === 'DESKTOP') {
    handleAppReady();
    // }
    getSceneList();
  }, []);

  const handleAppReady = () => {
    app
      .onReady()
      .then(() => {   
        console.log("app: " , app , "|| isLatestApp: " , isLatestApp());
        getUserDetails();
        setIsAppLoaded(true);
      })
      .catch(error => {
        console.log(" onReadyError :",error);
        setAppReadyError(true);
        setAppReadyErrorMsg('onReadyError');
      });
  };

  const getUserDetails = () => {
    if (isAppLoaded) {
      return true;
    }
    app.context
      .getUser()
      .then(user => {
        if (user.displayName) {
          setHasUserDetials(true);
        }
        setUserName(user.displayName);
        setUserEmail(user.email);
      })
      .catch(error => {
        console.log('Dashboard : getUser() promise failed ' + error);
      });
  };

  const _parseXML = ({ xmlData }) => {
    var xml = new XMLParser().parseFromString(xmlData);
    let names = [];
    let prefix = '';
    if (xml && xml.children) {
      xml.children.forEach((element, index) => {
        if (element.name === 'Prefix') {
          prefix = element.value;
        }
        if (element.name === 'Contents') {
          let tempName = element.children[0]['value'];
          tempName = tempName.replace(prefix, '');
          let tempNameSplit = tempName.split('/');
          let sceneName = tempNameSplit[0];
          if (sceneName !== '' && !names.includes(sceneName)) {
            names.push(sceneName);
          }
        }
      });
    }
    setItems(names);
  };

  const getSceneList = () => {
    if (!isSceneListLoaded) {
      scenes.scenesList(
        result => {
          setIsSceneListLoaded(true);
          _parseXML({ ...result });
        },
        error => {
          // auth.signout(() => navigate('/'));
          setError(error);
        }
      );
    }
  };

  const shareUrl = () => {
    if (active) {
      session.stop(
        sceneKey,
        data => {
          app.clearShareUrl();
        },
        error => {
          console.log('session stop API error:', error);
        }
      );
      setActive(!active);
    } else {
      if (app.isShared) {
        setSessionStartError(true);
        return;
      }
      scenes.generateTempKey(
        items[activeSceneIndex],
        data => {
          setActive(!active);
          setSceneKey(data.key);
          const url = `${process.env.REACT_APP_WEBEX_URL}/participant/${encodeURIComponent(data.sceneName)}/${encodeURIComponent(mode)}/${encodeURIComponent(
            data.key
          )}/${encodeURIComponent(localStorage.getItem('username'))}`;
          app
            .setShareUrl(url, url, 'Vection 3DFrame')
            .then(() => {
              let urlParms = new URLSearchParams({
                mode: 'host',
                key: data.key,
                name: userName,
                email: userEmail
              }).toString();
              if (isLatestApp()) {
                alert("isLatest App openUrlInSystemBrowser ");
                app.openUrlInSystemBrowser('frames://app?' + urlParms);
              } else {
                alert("window location");
                window.location = 'frames://app?' + urlParms;
              }
            })
            .catch(error => {
              setSessionStartError(true);
              console.log('setShareUrl() failed with error', error);
            });
        },
        error => {
          modal.show();
          setStripePlanError(error.message ? error.message : 'Something went wrong.Please try again.');
        }
      );
    }
  };

  const handleMode = mode => {
    !active && setMode(mode);
  };

  const handleItemSearch = (search) => {
    if (search) {
      setFilteredItems(items.filter(element => element.toLocaleLowerCase().match(search.toLocaleLowerCase())));
    } else {
      setFilteredItems(null);
    }
  }

  return (
    <>
      {sessionStartError && (
        <Modal title="Error" message="Could not start/stop while session is active. Please stop session first and try again." handleClose={() => setSessionStartError(false)} />
      )}

      {stripePlanError !== false && (
        <ErrorModal
          message={stripePlanError}
          handleClose={() => {
            modal.hide();
            setStripePlanError(false);
          }}
        />
      )}

      <section className="main-content scene-list dashboard-page">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="entry-title mb-0">Create your Metaverse.</h2>
          </div>
        </div>
        <div className="spacer-xs-2"></div>
        {isAppLoaded ? (
          <>
            <div className="row gx-3">
              <h6 className='pb-1'><b>Choose the role of your participants:</b></h6>
              <div className="col-6">
                <div className={'form-check bordercheck ' + (mode === 'editor' ? 'active' : '') + (active ? 'not-allowed' : '')}>
                  <input
                    className={'form-check-input custom-clickable ' + (active ? 'not-allowed' : '')}
                    type="radio"
                    name="modeRadio"
                    id="modeRadio-editor"
                    onChange={() => {
                      if (!active) {
                        handleMode('editor');
                      }
                    }}
                    value="editor"
                    checked={mode === 'editor'}
                  />
                  <label
                    className={'form-check-label custom-clickable ' + (active ? 'not-allowed' : '')}
                    onClick={() => {
                      if (!active) {
                        handleMode('editor');
                      }
                    }}
                  >
                    Editor
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className={'form-check bordercheck ' + (mode === 'viewer' ? 'active' : '')}>
                  <input
                    className={'form-check-input custom-clickable ' + (active ? 'not-allowed' : '')}
                    type="radio"
                    name="modeRadio"
                    id="modeRadio-viewer"
                    value="viewer"
                    onChange={() => {
                      if (!active) {
                        handleMode('viewer');
                      }
                    }}
                    checked={mode === 'viewer'}
                  />
                  <label
                    className={'form-check-label custom-clickable ' + (active ? 'not-allowed' : '')}
                    onClick={() => {
                      handleMode('viewer');
                    }}
                  >
                    Viewer
                  </label>
                </div>
              </div>
              {/* <div className="spacer-xs-3"></div> */}
            </div>
            <div className="spacer-xs-4"></div>
            {!hasUserDetials && (
              <div className="row ">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    localStorage.setItem('meeting_username', userName);
                  }}
                >
                  <label className="float-left">
                    Name &nbsp;
                    <img
                      className="custom-question-icon"
                      src={questionSVG}
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Username access permission missing. Please contact your webex administrator."
                    />
                  </label>
                  <div className="mb-3 mt-1">
                    <input
                      type="text"
                      className="form-control user-floating-input"
                      id="floatingInput"
                      placeholder="Your Name"
                      required={true}
                      value={userName}
                      onChange={e => {
                        setUserName(e.target.value);
                        localStorage.setItem('meeting_username', e.target.value);
                      }}
                    />
                    <div className="spacer-xs-1"></div>
                    {!userName && <span className="pt-1 text-danger">Please enter your name to start scene</span>}
                  </div>
                </form>
              </div>
            )}
            <div className="spacer-xs-1"></div>
          </>
        ) : (
          <></>
        )}

        <h1 className="entry-heading mb-0">Scene List</h1>

        <div className="spacer-xs-2"></div>
        <form
          className='search-wrapper'
          onSubmit={e => {
            e.preventDefault();
          }}
        >
            <input
              name="search"
              type="text"
              className="d-inline form-control user-floating-input search-input"
              id="floatingInput2"
              placeholder="Search"
              required={true}
              onChange={e => {
                handleItemSearch(e.target.value);
              }}
            />
        </form>

        <div className="spacer-xs-2"></div>
        <div className="scene-list-wrapper">
          <ListData
            key={'scene-list-data'}
            error={error}
            isSceneListLoaded={isSceneListLoaded}
            items={filteredItems ? filteredItems : items}
            activeSceneIndex={activeSceneIndex}
            setActiveSceneIndex={setActiveSceneIndex}
            sessionIsActive={active}
            handleItemSearch={handleItemSearch}
          />
        </div>

        <div className="spacer-xs-2"></div>
        <div className="col-sm-12">
          <div className={'add-btn text-center ' + (active ? 'not-allowed' : '')}>
            <span
              onClick={() => {
                if (!active) {
                  navigate('/create-scene');
                }
              }}
              className={'add-btn custom-clickable ' + (active ? 'not-allowed' : '')}
            >
              <img src={addSVG} className="img-fluid" />
            </span>
          </div>
        </div>
        {
          isAppLoaded && userName && isSceneListLoaded && items && items && items.length > 0 && (
            <div className="fixed-bottom d-flex justify-content-center w-100">
              <div className="d-flex justify-content-center custom-container">
                <input
                  type="button"
                  className={'btn btn-primary d-flex justify-content-center w-100 '}
                  onClick={() => {
                    shareUrl();
                  }}
                  value={active ? 'Stop your scene' : 'Start your scene'}
                />
              </div>
            </div>
          )
        }
      </section >
      {appReadyError ? <p className="col-12 mt-3 text-center text-danger">{appReadyErrorMsg}</p> : <></>
      }
    </>
  );
}

function ListData({ error, isSceneListLoaded, items, activeSceneIndex, setActiveSceneIndex, sessionIsActive }) {
  const _renderImagePreview = sceneName => {
    const key = CryptoJS.enc.Hex.parse('3214865890bac31f');
    const iv = CryptoJS.enc.Hex.parse('1234567890abcdef');

    let imageId = `scene-preview-${_getFormatedName(sceneName)}`;
    scenes.imagePreview(
      sceneName,
      data => {
        if (data.url) {
          fetch(data.url)
            .then(response => {
              return response.text();
            })
            .then(text => {
              // let imageElement = document.getElementById(imageId);
              // imageElement.src = `data:image/jpeg;base64,${imageResponse}`
              var decrypted = CryptoJS.DES.decrypt(text.replaceAll('\u0000', ''), key, { iv: iv });
              let imageElement = document.getElementById(imageId);
              imageElement.src = `data:image/jpeg;base64,${decrypted.toString(CryptoJS.enc.Base64)}`;
            });
        }
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  if (error) {
    return <div className="col-12 mt-3 text-center text-danger">Error: {error.message}</div>;
  } else if (!isSceneListLoaded) {
    return (
      <div className="col-12 mt-3 text-center">
        <div className="spacer-xs-2"></div>
        <Loader />
        <div className="spacer-xs-2"></div>
      </div>
    );
  } else {
    console.log("items:", items);

    if (items && items.length > 0) {
      return (
        <>
          {items.map((item, index) => (
            <React.Fragment key={'scene-item-' + index}>
              <div className={"row custom-form-check custom-clickable  " + (sessionIsActive && 'not-allowed')} onClick={() => {
                if (!sessionIsActive) {
                  setActiveSceneIndex(index);
                }
              }}>
                <div className='col-1 custom-clickable '>
                  <input
                    className={'form-check-input scene-radio custom-clickable  ' + (sessionIsActive ? 'not-allowed' : '')}
                    type="radio"
                    name="sceneName"
                    id={'scene-radio-' + index}
                    checked={index === activeSceneIndex}
                    onChange={() => {
                      if (!sessionIsActive) {
                        setActiveSceneIndex(index);
                      }
                    }}
                  />
                </div>
                <div className="col-3 custom-clickable  preview-image-wrapper">
                  <img className='w-100 preview-image custom-clickable zoom' id={`scene-preview-${_getFormatedName(item)}`} src={noImageIcon512} />
                  {_renderImagePreview(item)}
                </div>
                <div className="col-7 custom-clickable  name-wrapper">
                  <label className={'form-check-label custom-clickable '}>
                    {item}
                  </label>
                </div>
              </div>
              <div className="spacer-xs-2"></div>
            </React.Fragment>
          ))}
        </>
      );
    } else {
      return <div className="col-12 mt-3 text-center">No Records Found</div>;
    }
  }
}
